import React, { useState } from "react"
import { MinusIcon, PlusIcon } from "../icons/icons"

const AmountPicker = ({ value, setValue }) => {
  const [inputValue, setInputValue] = useState(value[value.length - 1])
  const addToValue = newValue => {
    setInputValue(newValue + 1)
    setValue(prev => [...prev, newValue + 1])
  }
  const removeToValue = newValue => {
    setInputValue(newValue - 1)
    const newValues = value.filter(v => v !== inputValue)

    setValue(newValues)
  }

  return (
    <div className="flex flex-col  font-semibold">
      <p className="mb-2 ">Stops</p>
      <div className="flex w-min flex-row items-center justify-between gap-4">
        <button
          type="button"
          disabled={inputValue < 2 ? true : false}
          onClick={() => removeToValue(inputValue)}
          className=" inline-flex items-center rounded-lg bg-blue-700 p-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:bg-gray-300 hover:disabled:bg-gray-300 "
        >
          <MinusIcon />
          <span className="sr-only">Remove Stop</span>
        </button>

        <span className="p-1 text-lg">{value.length}</span>
        <button
          disabled={inputValue >= 8 ? true : false}
          className=" inline-flex items-center rounded-lg bg-blue-700 p-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:bg-gray-300 hover:disabled:bg-gray-300 "
          onClick={() => addToValue(inputValue)}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  )
}

export default AmountPicker
