import React, { useState } from "react"
import ColorPick from "./ColorPick"
import NewSlidePick from "./newSlidePick"

const NumberTab = ({
  numberColor,
  numberSize,
  setnumberSize,
  setnumberColor,
}) => {
  const [pickColor, setColorPick] = useState(false)

  return (
    <div className="relative flex h-full w-full flex-col gap-8 py-4 lg:gap-8">
      {!pickColor && (
        <NewSlidePick
          label="Number size"
          value={numberSize}
          setValue={setnumberSize}
          min={64}
          max={144}
        />
      )}
      <ColorPick
        setSelected={setColorPick}
        onChange={setnumberColor}
        color={numberColor}
        label="Number Color"
      />
    </div>
  )
}

export default NumberTab
