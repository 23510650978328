import React from "react"

const SidebarIcon = ({ icon, label, selected, handleSelect }) => {
  return (
    <li
      onClick={handleSelect}
      className={`
      ${
        selected ? "bg-gray-100" : "bg-inherit"
      } relative  my-0 flex h-full w-full min-w-max 
        cursor-pointer flex-col items-center justify-center gap-1 p-4 transition-all ease-in hover:bg-gray-100 lg:h-min`}
    >
      {icon}
      <span className="text-center text-xs "> {label}</span>
    </li>
  )
}

export default SidebarIcon
