import React, { useRef, useState } from "react"
import { CustomPicker } from "react-color"
import { AiOutlineArrowLeft } from "react-icons/ai"
import useOnClickOutside from "./functions/onClickOutside"
import "./style.css"
var { EditableInput } = require("react-color/lib/components/common")

const CustomColor = ({ color, label, onChange, setOpenSwatch }) => {
  const [inputValue, setInputValue] = useState(color)
  const colorPickerRef = useRef()
  useOnClickOutside(colorPickerRef, () => setOpenSwatch(false))

  const colors = [
    "#000000",
    "#545454",
    "#737373",
    "#a6a6a6",
    "#d9d9d9",
    "#ffffff",
    "#ff1616",
    "#ff5757",
    "#ff66c4",
    "#cb6ce6",
    "#8c52ff",
    "#5e17eb",
    "#03989e",
    "#00c2cb",
    "#5ce1e6",
    "#38b6ff",
    "#5271ff",
    "#004aad",
    "#008037",
    "#7ed957",
    "#c9e265",
    "#ffde59",
    "#ffbd69",
    "#ff914d",
  ]
  //text-m mb-2 block font-bold text-gray-700
  const inputStyles = {
    wrap: {
      display: "flex",
      flexDirection: "column-reverse",
      gap: "4px",
    },
    input: {
      border: "1px",
      borderRadius: "4px",
      width: "100%",
      padding: "8px 12px 12px 8px",
      appearance: "none",
      lineHeight: "1.625",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      ":focus": {
        boxShadow: "box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5)",
        outlineStyle: "none",
      },
    },
    label: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#374151",
    },
  }

  return (
    <div
      ref={colorPickerRef}
      className=" absolute left-0 top-0 z-50 flex w-full flex-col gap-4 bg-gray-100 pb-4"
    >
      <AiOutlineArrowLeft
        onClick={() => setOpenSwatch(false)}
        className="relative right-0 top-0 z-50 cursor-pointer bg-gray-100"
      />
      <span className="text-lg font-bold">{label}</span>
      <EditableInput
        style={inputStyles}
        label="Hex Color"
        value={inputValue}
        onChange={e => {
          onChange(e)
          setInputValue(e)
        }}
      />
      <span className="text-base font-bold">Default Colors</span>
      <div className="grid w-full grid-flow-row grid-cols-6 gap-1">
        {colors.map(color => (
          <div
            style={{
              backgroundColor: color,
            }}
            className={`h-10 w-10 cursor-pointer rounded border-2  hover:border-gray-400`}
            onClick={() => {
              onChange(color)
              setInputValue(color)
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default CustomPicker(CustomColor)
