import React, { useState } from "react"
import ColorPick from "./ColorPick"
import FilePicker from "./FilePicker"
import NewSlidePick from "./newSlidePick"
const InfoTab = props => {
  const [pickColor, setColorPick] = useState(false)

  return (
    <div
      className={`relative flex h-full w-full flex-col  gap-8  py-4 lg:gap-8 `}
    >
      {" "}
      {!pickColor && (
        <>
          <FilePicker
            label={props.logoPickLabel}
            handleFile={props.handleLogoFile}
          />
          <div>
            <label
              className="text-m mb-2 block font-bold text-gray-700"
              htmlFor="kilos"
            >
              Max Capacity Kilos
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-relaxed text-gray-700 shadow focus:outline-none"
              id="kilos"
              type="text"
              placeholder="480KG"
              maxLength={8}
              value={props.kilos}
              onChange={e => {
                props.setKilos(e.target.value)
                props.measureKiloText()
              }}
            />
          </div>
          <div>
            <label
              className="text-m mb-2 block font-bold text-gray-700"
              htmlFor="passengers"
            >
              Max Passengers
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-relaxed text-gray-700 shadow focus:outline-none"
              id="persons"
              type="text"
              placeholder="4"
              maxLength={5}
              value={props.persons}
              onChange={e => {
                props.setPersons(e.target.value)
                props.measurePersonsText()
              }}
            />
          </div>

          <NewSlidePick
            label="Font Size"
            value={props.infoSize}
            setValue={props.setInfoSize}
            min={12}
            max={64}
          />
        </>
      )}
      <ColorPick
        setSelected={setColorPick}
        color={props.color}
        onChange={props.setColor}
        label="Info Tag Color"
      />
    </div>
  )
}

export default InfoTab
