import { saveAs } from "file-saver"
import { graphql } from "gatsby"
import JSZip from "jszip"
import React, { useEffect, useRef, useState } from "react"
import { BiDialpadAlt } from "react-icons/bi"
import { IoSwapVertical } from "react-icons/io5"
import { Group, Layer, Line, Rect, Stage, Text } from "react-konva"
import useImage from "use-image"
import BackgroundTab from "../components/aquariusEdit/BackgroundTab"
import ButtonTab from "../components/aquariusEdit/ButtonTab"
import config from "../components/aquariusEdit/config.json"
import useOnClickOutside from "../components/aquariusEdit/functions/onClickOutside"
import InfoTab from "../components/aquariusEdit/InfoTab"
import InnerSidebar from "../components/aquariusEdit/InnerSidebar"
import NumberTab from "../components/aquariusEdit/NumberTab"
import SidebarIcon from "../components/aquariusEdit/SidebarIcon"
import {
  DownloadIcon,
  InfoIcon,
  WallpaperIcon,
} from "../components/icons/icons"
const AquariusEdit = ({ pageContext }) => {
  const [height, setHeight] = useState(100)
  const [width, setWidth] = useState(100)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [control, setControl] = useState()
  const pageContainerRef = useRef()
  const canvasRef = useRef()
  const backgroundRef = useRef()

  //BACKGROUND TAB STATE
  const [backgroundFile, setBackgroundFile] = useState("")
  const [backgroundURL, setBackgroundURL] = useState([])
  const [backgroundFiles, setBackgroundFiles] = useState([])
  const [transparency, setTransparency] = useState(false)

  const [previewBackgroundURL, setPreviewBackgroundURL] = useState()
  const [backgroundColor, setBackgroundColor] = useState("#0b99cd")
  const [aspectWarning, setAspectWarning] = useState(false)
  const [alertImgSize, setAlertImgSize] = useState(false)
  //INFO TAB STATE
  const [logoFile, setLogoFile] = useState()
  const [logoURL, setLogoURL] = useState()

  const [kilos, setKilos] = useState("480")
  const [kilosTextWidth, setKilosTextWidth] = useState()

  const [persons, setPersons] = useState("4")
  const [personsTextWidth, setPersonsTextWidth] = useState()

  const kiloRef = useRef()
  const personsRef = useRef()
  const targaRef = useRef()

  const [infoColor, setInfoColor] = useState("#ffffff")
  const [infoSize, setInfoSize] = useState(32)

  //BUTTON STATE
  const [floors, setFloors] = useState([0, 1])
  const [addOpen, setAddOpen] = useState(true)
  const [openPoints, setOpenPoints] = useState([0, 100, 50, 50, 0, 0])
  const [closePoints, setClosePoints] = useState([0, -100, -50, -50, 0, 0])

  const [linePoints, setLinePoints] = useState([0, 100, 0, 0])
  const [floorBtnColor, setFloorBtnColor] = useState("#ffffff")
  const [floorTxTColor, setFloorTxTColor] = useState("#0b99cd")
  const [floorTxTSize, setFloorTxTSize] = useState(40)

  const [strokeColor, setStrokeColor] = useState("#ffffff")
  const [strokeWidth, setStrokeWidth] = useState(10)
  const [cornerRadius, setCornerRadius] = useState(8)
  const [enableStroke, setEnableStroke] = useState(false)
  const [enableFill, setEnableFill] = useState(true)
  const [frameColor, setFrameColor] = useState("#008037")
  const buttonTextRef = useRef()
  const buttonRef = useRef([])
  const openRef = useRef()
  const closeRef = useRef()
  //DEFAULT TEXT
  const defaultTextRef = useRef()
  const defaultDoubleDigitTextRef = useRef()
  //NUMBER AND ARROW STATE
  const numberRef = useRef([])
  const upArrowRef = useRef()
  const downArrowRef = useRef()
  const [numberValue, setNumberValue] = useState("0")
  const [numberColor, setnumberColor] = useState("#ffffff")
  const [numberTextWidth, setNumberTextWidth] = useState()
  const [numberSize, setnumberSize] = useState(82)
  const [arrowPoints, setArrowPoints] = useState([
    10, 100, 50, 80, 90, 100, 50, 0,
  ])
  const getFloorPosition = index => {
    const getRow = () => {
      switch (index) {
        case 0:
          return 1
        case 1:

        case 2:
          return 2
        case 3:
        case 4:
          return 3
        case 5:
        case 6:
          return 4
        case 7:
        case 8:
          return 5
        case 9:
          return 3
      }
    }

    let padding = strokeWidth / 1.5 + (25 * width) / 1080

    let buttonSize = (250 * width) / 1080
    let buttonCenter = buttonSize / 2
    let halfCanvasWidth = width / 2
    let position = { x: halfCanvasWidth - buttonCenter, y: 0 }
    let newIndex = index + 1
    let buttonPadded = buttonSize + padding
    let y = 0
    let openCloseRow = addOpen ? buttonSize + padding : padding
    let newHeight = height - openCloseRow
    switch (floors.length) {
      default:
        y = height - buttonPadded * newIndex
        position.y = y
        return position
      case 2:
      case 3:
        y = newHeight - buttonPadded * newIndex
        position.y = y
        return position
      case 4:
        if (addOpen) {
          if (index % 2) {
            position = { x: halfCanvasWidth + padding, y: 0 }
            newIndex = (index + 1) / 2
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * newIndex
            position.y = y
            return position
          } else {
            position = { x: halfCanvasWidth - padding - buttonCenter * 2, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getRow()
            position.y = y
            return position
          }
        } else {
          y = newHeight - buttonPadded * newIndex
          position.y = y
          return position
        }
      case 5:
      case 7:
        if (index == 0) {
          y = height - buttonPadded * newIndex
          position.y = y
          return position
        } else if (index % 2) {
          position = { x: halfCanvasWidth - padding - buttonCenter * 2, y: 0 }
          buttonPadded = buttonSize + padding
          y = height - buttonPadded * getRow()
          position.y = y
          return position
        } else {
          position = { x: halfCanvasWidth + padding, y: 0 }
          buttonPadded = buttonSize + padding
          y = height - buttonPadded * getRow()
          position.y = y
          return position
        }
      case 9:
        const getGridRow = () => {
          switch (index) {
            case 0:
            case 1:
            case 2:
              return 1
            case 3:
            case 4:
            case 5:
              return 2
            case 6:
            case 7:
            case 8:
              return 3
          }
        }
        if (index == 0 || index == 3 || index == 6) {
          position = { x: halfCanvasWidth - padding - buttonCenter * 3, y: 0 }
          buttonPadded = buttonSize + padding
          y = newHeight - buttonPadded * getGridRow()
          position.y = y
          return position
        } else if (index == 1 || index == 4 || index == 7) {
          position = { x: halfCanvasWidth - buttonCenter, y: 0 }
          buttonPadded = buttonSize + padding
          y = newHeight - buttonPadded * getGridRow()
          position.y = y
          return position
        } else if (index == 2 || index == 5 || index == 8) {
          position = { x: halfCanvasWidth + padding + buttonCenter, y: 0 }
          buttonPadded = buttonSize + padding
          y = newHeight - buttonPadded * getGridRow()
          position.y = y
          return position
        }
      case 6:
        if (index % 2) {
          position = { x: halfCanvasWidth + padding, y: 0 }
          newIndex = (index + 1) / 2
          buttonPadded = buttonSize + padding
          y = newHeight - buttonPadded * newIndex
          position.y = y
          return position
        } else {
          position = { x: halfCanvasWidth - padding - buttonCenter * 2, y: 0 }
          buttonPadded = buttonSize + padding
          y = newHeight - buttonPadded * getRow()
          position.y = y
          return position
        }
      case 8:
        const getEightGridRow = () => {
          switch (index) {
            case 0:
            case 1:
              return 1
            case 2:
            case 3:
            case 4:
              return 2
            case 5:
            case 6:
            case 7:
              return 3
          }
        }
        if (addOpen) {
          if (index == 0) {
            position = { x: halfCanvasWidth - padding - buttonCenter * 2, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getEightGridRow()
            position.y = y
            return position
          } else if (index == 1) {
            position = { x: halfCanvasWidth + padding, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getEightGridRow()
            position.y = y
            return position
          } else if (index == 2 || index == 6) {
            position = { x: halfCanvasWidth - padding - buttonCenter * 3, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getEightGridRow()
            position.y = y
            return position
          } else if (index == 3 || index == 7) {
            position = { x: halfCanvasWidth - buttonCenter, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getEightGridRow()
            position.y = y
            return position
          } else if (index == 4 || index == 5 || index == 8) {
            position = { x: halfCanvasWidth + padding + buttonCenter, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getEightGridRow()
            position.y = y
            return position
          }
        } else {
          if (index % 2) {
            position = { x: halfCanvasWidth + padding, y: 0 }
            newIndex = (index + 1) / 2
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * newIndex
            position.y = y
            return position
          } else {
            position = { x: halfCanvasWidth - padding - buttonCenter * 2, y: 0 }
            buttonPadded = buttonSize + padding
            y = newHeight - buttonPadded * getRow()
            position.y = y
            return position
          }
        }
    }
  }
  const getOpenPosition = isClose => {
    let position = { x: 0, y: 0 }
    let buttonPosition = getFloorPosition(0)

    let buttonSize = (250 * width) / 1080
    let padding = parseInt(strokeWidth) / 1.5 + (25 * width) / 1080
    let buttonPadded = buttonSize + padding

    let y = buttonPosition.y + buttonPadded
    let x = buttonPosition.x - buttonPadded / 2
    if (isClose) {
      x = buttonPosition.x + buttonPadded / 2
    }
    switch (floors.length) {
      default:
        return position
      case 2:
      case 3:
        position = { x: x, y: y }

        return position
      case 4:
        x = buttonPosition.x + buttonPadded + padding
        if (isClose) {
          x = buttonPosition.x
        }
        position = { x: x, y: y }

        return position
      case 5:
        x = buttonPosition.x - buttonPadded - padding
        y = buttonPosition.y
        if (isClose) {
          x = buttonPosition.x + buttonPadded + padding
        }
        position = { x: x, y: y }
        return position
      case 6:
        x = buttonPosition.x
        y = buttonPosition.y + buttonPadded
        if (isClose) {
          x = buttonPosition.x + buttonPadded + padding
        }
        position = { x: x, y: y }
        return position
      case 7:
        x = buttonPosition.x - buttonPadded - padding
        y = buttonPosition.y
        if (isClose) {
          x = buttonPosition.x + buttonPadded + padding
        }
        position = { x: x, y: y }
        return position
      case 8:
        x = buttonPosition.x
        y = buttonPosition.y + buttonPadded
        if (isClose) {
          x = buttonPosition.x + buttonPadded + padding
        }
        position = { x: x, y: y }
        return position
      case 9:
        x = buttonPosition.x
        y = buttonPosition.y + buttonPadded
        if (isClose) {
          x = buttonPosition.x + buttonPadded * 2
        }
        position = { x: x, y: y }
        return position
    }
  }
  const getOpenLinePosition = isClose => {
    let position = getOpenPosition(isClose)
    let buttonSize = (250 * width) / 1080

    let x = position.x
    let y = position.y + (25 * width) / 1080
    position = { x: x, y: y }

    return position
  }
  const getLinePosition = () => {
    let position = getOpenPosition(false)
    let buttonSize = (250 * width) / 1080
    let padding = (45 * width) / 1080
    let y = position.y + (25 * width) / 1080
    position = { x: position.x + buttonSize / 2 - padding, y: y }
    return position
  }
  const getTextPosition = floor => {
    const halfButtonScaled = (125 * width) / 1080
    const positionX =
      getFloorPosition(floor).x +
      halfButtonScaled -
      measureButtonText(floor).x / 2

    const positionY =
      getFloorPosition(floor).y +
      halfButtonScaled -
      measureButtonText(floor).y / 2
    const textPosition = { x: positionX, y: positionY }
    return textPosition
  }

  const measureKiloText = () => {
    const textWidth = kiloRef.current.measureSize(kilos + "KG")
    setKilosTextWidth(textWidth)
  }
  const measurePersonsText = () => {
    const textWidth = personsRef.current.measureSize(persons + "Max")
    setPersonsTextWidth(textWidth)
  }
  const measureNumberText = () => {
    const textHeight =
      numberRef.current[0].measureSize("0").height - numberSize * 0.2
    const textWidth = numberRef.current[0].measureSize("0").width
    setNumberTextWidth(textWidth)

    setArrowPoints([
      0,
      textHeight,
      textWidth * 0.5,
      textHeight * 0.7,
      textWidth,
      textHeight,
      textWidth * 0.5,
      textHeight * 0.1,
    ])
  }
  const drawOpenClose = width => {
    let buttonSize = (100 * width) / 1080
    let padding = (45 * width) / 1080

    setOpenPoints([
      padding,
      buttonSize * 2 - padding,
      buttonSize,
      buttonSize,
      padding,
      padding,
    ])
    setLinePoints([padding, buttonSize * 2 - padding, padding, padding])

    setClosePoints([
      buttonSize,
      buttonSize * 2 - padding,
      padding,
      buttonSize,
      buttonSize,
      padding,
    ])
  }
  const measureButtonText = text => {
    const buttonTextPosition = { x: 0, y: 0 }

    if (text <= 9) {
      buttonTextPosition.x = defaultTextRef.current?.measureSize(text).width
      buttonTextPosition.y = defaultTextRef.current?.measureSize(text).height
    } else {
      buttonTextPosition.x =
        defaultDoubleDigitTextRef.current?.measureSize(text).width
      buttonTextPosition.y =
        defaultDoubleDigitTextRef.current?.measureSize(text).height
    }

    return buttonTextPosition
  }

  const PreviewImage = () => {
    const [imageElement] = useImage(previewBackgroundURL)
    return imageElement
  }
  const handleBgFile = e => {
    setBackgroundColor()
    setAlertImgSize(false)
    setAspectWarning(false)

    var tempArray = []
    let tempFilesArray = []
    for (var i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size <= 2097152 * 1.5) {
        var fileURL = URL.createObjectURL(e.target.files[i])

        var img = new Image()
        img.onload = function () {
          if (img.width > img.height || img.width < 1080 || img.height < 1920) {
            setAspectWarning(true)
          }
        }
        img.src = fileURL
        tempArray.push(fileURL)
        tempFilesArray.push({ file: e.target.files[i], floor: i })
      } else {
        setAlertImgSize(true)
      }
    }
    setBackgroundURL(tempArray)
    setPreviewBackgroundURL(tempArray[0])
    setBackgroundFiles(tempFilesArray)
  }
  const LogoImage = () => {
    const [logoElement] = useImage(logoURL)
    return logoElement
  }

  const handleLogoFile = e => {
    setLogoFile()
    if (e.target.files.length > 0) {
      var logoURL = URL.createObjectURL(e.target.files[0])
      setLogoURL(logoURL)
    }
  }
  const handleControl = control => {
    setControl(control)

    setOpenSidebar(true)
  }
  const buildConfigFile = () => {
    const REAL_WIDTH = 1080
    const pixelRatio = REAL_WIDTH / width
    let buttonSize = (250 * width) / 1080
    let buttonCenter = (buttonSize * pixelRatio) / 2
    config.scenary[2].visible = transparency

    config.scenary[4].key_close[0].visible = addOpen.toString()
    config.scenary[4].key_close[0].x_center = (
      buttonCenter +
      getOpenPosition().x * pixelRatio
    ).toString()

    config.scenary[4].key_close[0].y_center = (
      buttonCenter +
      getOpenPosition().y * pixelRatio
    ).toString()

    config.scenary[4].key_open[0].visible = addOpen.toString()
    config.scenary[4].key_open[0].x_center = (
      buttonCenter +
      getOpenPosition(true).x * pixelRatio
    ).toString()
    config.scenary[4].key_open[0].y_center = (
      buttonCenter +
      getOpenPosition(true).y * pixelRatio
    ).toString()
    for (let i = 0; i < floors.length; i++) {
      config.scenary[4].key_floor[i].x_center = (
        buttonCenter +
        getFloorPosition(i).x * pixelRatio
      ).toString()

      config.scenary[4].key_floor[i].y_center = (
        buttonCenter +
        getFloorPosition(i).y * pixelRatio
      ).toString()
      config.scenary[4].key_floor[i].visible = "true"

      if (config.scenary[4].key_floor.length > floors.length) {
        for (
          let j = floors.length;
          j < config.scenary[4].key_floor.length;
          j++
        ) {
          config.scenary[4].key_floor[j].visible = "false"
        }
      }
    }
    config.scenary[4].frame[0].color = frameColor
    config.scenary[4].frame[0].aspect =
      cornerRadius == 8 ? "rectangle" : "circle"

    const numberWidth = width / 2 - numberTextWidth / 2
    const numberHeight = height * 0.25
    config.scenary[5].x_center = (numberWidth * pixelRatio).toString()
    config.scenary[5].y_center = (numberHeight * pixelRatio).toString()
    const arrowWidth = width / 2 + numberTextWidth / 2
    config.scenary[6].x_center = (arrowWidth * pixelRatio).toString()
    config.scenary[6].y_center = (numberHeight * pixelRatio).toString()
  }

  function sidebarContent(control) {
    switch (control) {
      case "wallpaper":
        return (
          <BackgroundTab
            backgroundFile={backgroundFile}
            backgroundFiles={backgroundFiles}
            setBackgroundFiles={setBackgroundFiles}
            backgroundURL={backgroundURL}
            previewBackgroundURL={previewBackgroundURL}
            setBackgroundURL={setBackgroundURL}
            setPreviewBackgroundURL={setPreviewBackgroundURL}
            color={backgroundColor}
            label="Background Color"
            setColor={setBackgroundColor}
            handleBgFile={handleBgFile}
            transparency={transparency}
            setTransparency={setTransparency}
            warning={aspectWarning}
            alertImgSize={alertImgSize}
            setWarning={setAspectWarning}
            setAlertImgSize={setAlertImgSize}
            floors={floors}
          />
        )
      case "dialpad":
        return (
          <ButtonTab
            setFloors={setFloors}
            floors={floors}
            setAddOpen={setAddOpen}
            addOpen={addOpen}
            setEnableFill={setEnableFill}
            enableFill={enableFill}
            floorBtnColor={floorBtnColor}
            setFloorBtnColor={setFloorBtnColor}
            floorTxTColor={floorTxTColor}
            setFloorTxTColor={setFloorTxTColor}
            floorTxTSize={floorTxTSize}
            setFloorTxTSize={setFloorTxTSize}
            setEnableStroke={setEnableStroke}
            enableStroke={enableStroke}
            strokeColor={strokeColor}
            setStrokeColor={setStrokeColor}
            strokeWidth={strokeWidth}
            setStrokeWidth={setStrokeWidth}
            cornerRadius={parseInt(cornerRadius)}
            setCornerRadius={setCornerRadius}
            frameColor={frameColor}
            setFrameColor={setFrameColor}
          />
        )
      case "info":
        return (
          <InfoTab
            kilos={kilos}
            setKilos={setKilos}
            persons={persons}
            setPersons={setPersons}
            measureKiloText={measureKiloText}
            measurePersonsText={measurePersonsText}
            color={infoColor}
            setColor={setInfoColor}
            logoPickLabel="Upload your logo"
            handleLogoFile={handleLogoFile}
            setInfoSize={setInfoSize}
            infoSize={infoSize}
          />
        )
      case "download":
        return (
          <div className="my-4 flex flex-col gap-2">
            <p className="text-center text-xl font-semibold">
              Download all the files for your custom Aquarius
            </p>
            <button
              type="button"
              className="inline-flex items-center justify-center gap-2 rounded-full bg-blue-700 py-2.5 px-5 text-white  hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 "
              onClick={() => downloadZip()}
            >
              <span className="text-base font-semibold"> Download</span>
              <DownloadIcon className="ml-2" />
            </button>
          </div>
        )

      case "number":
        return (
          <NumberTab
            numberColor={numberColor}
            numberSize={numberSize}
            setnumberColor={setnumberColor}
            setnumberSize={setnumberSize}
          />
        )
    }
  }

  const [openBlob, setOpenBlob] = useState()
  const [closeBlob, setCloseBlob] = useState()
  const [targaBlob, setTargaBlob] = useState()
  const [bgColorBlob, setbgColorBlob] = useState()
  const [arrowUpBlob, setArrowUpBlob] = useState()
  const [arrowDownBlob, setArrowDownBlob] = useState()
  const [pulsanteBlob, setPulsanteBlob] = useState([])
  const [numeroBlob, setNumeroBlob] = useState([])
  const REAL_WIDTH = 1080
  const [pixelRatio, setPixelRatio] = useState(REAL_WIDTH / width)

  const VIRTUAL_WIDTH = 1080
  const [scale, setScale] = useState(1)

  useEffect(() => {
    setHeight(pageContainerRef.current.offsetHeight)
  }, [pageContainerRef.current?.offsetHeight])

  useEffect(() => {
    setWidth((height * 9) / 16)
  }, [height])

  useEffect(() => {
    setScale(width / VIRTUAL_WIDTH)
  }, [width])

  useEffect(() => {
    setPixelRatio(VIRTUAL_WIDTH / width)
  }, [width])

  useEffect(() => {
    drawOpenClose(width)
  }, [addOpen, floorTxTColor, width])

  useEffect(() => {
    setOpenBlob(openRef.current?.toBlob({ pixelRatio }))
    setCloseBlob(closeRef.current?.toBlob({ pixelRatio }))
  }, [pixelRatio, addOpen, floorTxTColor])

  useEffect(() => {
    measureKiloText()
    measurePersonsText()
    measureNumberText()
  }, [infoSize, kilos, persons, numberSize])

  useEffect(() => {
    setTargaBlob(targaRef.current.toBlob({ pixelRatio }))
  }, [infoSize, kilos, persons, infoColor, logoURL, pixelRatio])

  useEffect(() => {
    setArrowUpBlob(upArrowRef.current.toBlob({ pixelRatio }))
    setArrowDownBlob(downArrowRef.current.toBlob({ pixelRatio }))
    var numberTempArray = []

    for (var j = 0; j < numberRef.current.length; j++) {
      numberRef.current[j] &&
        numberTempArray.push(numberRef.current[j].toBlob({ pixelRatio }))
      setNumeroBlob(numberTempArray)
    }
  }, [numberSize, numberColor, pixelRatio])

  useEffect(() => {
    setbgColorBlob(backgroundRef.current.toBlob({ pixelRatio }))
  }, [backgroundColor])

  useEffect(() => {
    if (backgroundURL.length == 0) {
      setPreviewBackgroundURL()
      if (!backgroundColor) {
        setBackgroundColor("#0b99cd")
      }
    }
  }, [backgroundURL, backgroundColor])

  useEffect(() => {
    var numberTempArray = []

    for (var j = 0; j < numberRef.current.length; j++) {
      numberRef.current[j] &&
        numberTempArray.push(numberRef.current[j].toBlob({ pixelRatio }))
      setNumeroBlob(numberTempArray)
    }
  }, [numberColor, numberSize])

  useEffect(() => {
    var pulsanteTempArray = []
    for (var i = 0; i < buttonRef.current.length; i++) {
      buttonRef.current[i] &&
        pulsanteTempArray.push(buttonRef.current[i].toBlob({ pixelRatio }))
      setPulsanteBlob(pulsanteTempArray)
    }
  }, [
    floorBtnColor,
    floorTxTColor,
    floorTxTColor,
    cornerRadius,
    enableFill,
    enableStroke,
    strokeColor,
    strokeWidth,
  ])

  const downloadZip = () => {
    let zip = new JSZip()
    if (addOpen) {
      zip.file("pulsante_chiudiporta.png", openBlob, {
        binary: true,
      })
      zip.file("pulsante_apriporta.png", closeBlob, {
        binary: true,
      })
    }
    zip.file("targa_logo.png", targaBlob)
    zip.file("ArrowUp.png", arrowUpBlob)
    zip.file("ArrowDown.png", arrowDownBlob)
    for (var i = 0; i < pulsanteBlob.length; i++) {
      zip.file(`pulsante_${i}.png`, pulsanteBlob[i], { binary: true })
    }
    for (var i = 0; i < numeroBlob.length; i++) {
      zip.file(`numero_${i}.png`, numeroBlob[i], { binary: true })
    }
    if (backgroundFiles.length == 0) {
      zip.file("sfondo.png", bgColorBlob, { binary: true })
    }
    for (var i = 0; i < backgroundFiles.length; i++) {
      zip.file(
        `sfondo_${backgroundFiles[i].floor}.png`,
        backgroundFiles[i].file
      )
    }
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "aquarius.zip")
    })
  }

  const sideBarRef = useRef()
  useOnClickOutside(sideBarRef, () => setOpenSidebar(false))

  return (
    <div className="fixed inset-0 m-0 box-border  h-full w-full  overflow-hidden bg-slate-200 p-0 ">
      <div className=" flex h-full w-full flex-col-reverse lg:flex-row">
        <div
          ref={sideBarRef}
          className="flex flex-col-reverse bg-white lg:h-full lg:flex-row"
        >
          <ul className=" m-0 flex overflow-x-auto  lg:h-full lg:flex-col">
            <SidebarIcon
              key={"Background"}
              icon={<WallpaperIcon />}
              label={"Background"}
              handleSelect={() => {
                handleControl("wallpaper")
              }}
              selected={control === "wallpaper"}
            />
            <SidebarIcon
              key={"Info"}
              icon={<InfoIcon />}
              label="Info"
              handleSelect={() => handleControl("info")}
              selected={control === "info"}
            />
            <SidebarIcon
              key={"Buttons"}
              icon={<BiDialpadAlt className="h-6 w-6" />}
              label="Buttons"
              selected={control === "dialpad"}
              handleSelect={() => handleControl("dialpad")}
            />
            <SidebarIcon
              key={"Number"}
              icon={<IoSwapVertical className="h-6 w-6" />}
              label="Number"
              selected={control === "number"}
              handleSelect={() => handleControl("number")}
            />

            <SidebarIcon
              label="Download"
              key={"Download"}
              selected={control === "download"}
              icon={<DownloadIcon />}
              handleSelect={() => handleControl("download")}
            />
          </ul>

          <InnerSidebar
            isOpen={openSidebar}
            handleSidebar={() => setOpenSidebar(false)}
            content={sidebarContent(control)}
          />
        </div>
        <div
          className="flex h-full w-full scale-75 items-end justify-center lg:scale-100"
          ref={pageContainerRef}
        >
          <Stage height={height} width={width} scale={scale} ref={canvasRef}>
            <Layer>
              <Text
                ref={defaultTextRef}
                fontSize={parseInt(floorTxTSize)}
                verticalAlign="middle"
                x={0}
                y={0}
                fontStyle="bold"
                text={"0"}
                fill={"#000000"}
              />
              <Text
                ref={defaultDoubleDigitTextRef}
                fontSize={parseInt(floorTxTSize)}
                verticalAlign="middle"
                x={0}
                y={0}
                fontStyle="bold"
                text={"10"}
                fill={"#000000"}
              />
              <Line
                closed
                ref={downArrowRef}
                x={width / 2 + numberTextWidth * 0.5}
                y={height * 0.2}
                points={arrowPoints}
                fill={numberColor}
                scaleY={-1}
              />
              {floors.map((floor, index) => (
                <Text
                  ref={el => (numberRef.current[index] = el)}
                  key={"number " + floor}
                  fontSize={parseInt(numberSize)}
                  verticalAlign="top"
                  x={width / 2 - numberTextWidth / 2}
                  y={height * 0.2}
                  fontStyle="bold"
                  text={floor}
                  fill={numberColor}
                />
              ))}
              <Group ref={backgroundRef}>
                <Rect
                  x={0}
                  y={0}
                  height={height}
                  width={width}
                  fill={backgroundColor}
                  fillPatternImage={PreviewImage()}
                  fillPatternScaleY={height / PreviewImage()?.height}
                  fillPatternScaleX={width / PreviewImage()?.width}
                />
                {transparency && (
                  <Rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    fill={"#00000052"}
                  />
                )}
              </Group>
              <Group ref={targaRef} width={width} height={height}>
                <Text x={0} y={0} text={""} fontSize={1} />

                <Rect
                  x={width * 0.05}
                  y={width * 0.05}
                  width={width / 4}
                  height={width / 4}
                  fillPatternImage={LogoImage()}
                  fillPatternScaleY={width / 4 / LogoImage()?.height}
                  fillPatternScaleX={width / 4 / LogoImage()?.width}
                />
                <Text
                  x={
                    kilosTextWidth?.width > personsTextWidth?.width
                      ? width -
                        parseInt(kilosTextWidth?.width) -
                        width * 0.07 -
                        infoSize
                      : width -
                        parseInt(personsTextWidth?.width) -
                        width * 0.07 -
                        infoSize
                  }
                  y={height * 0.05}
                  text={"groups"}
                  fontSize={parseInt(infoSize)}
                  className="material-symbols-rounded"
                  fontFamily={"Material Symbols Rounded"}
                  fill={infoColor}
                />
                <Text
                  ref={personsRef}
                  x={width - parseInt(personsTextWidth?.width) - width * 0.05}
                  y={height * 0.05}
                  text={persons + " Max"}
                  fontSize={parseInt(infoSize)}
                  fontStyle="bold"
                  fontFamily={"DM sans"}
                  fill={infoColor}
                />
                <Text
                  x={
                    kilosTextWidth?.width > personsTextWidth?.width
                      ? width -
                        parseInt(kilosTextWidth?.width) -
                        width * 0.07 -
                        infoSize
                      : width -
                        parseInt(personsTextWidth?.width) -
                        width * 0.07 -
                        infoSize
                  }
                  y={height * 0.05 + parseInt(infoSize)}
                  text={"weight"}
                  fontSize={parseInt(infoSize)}
                  className="material-symbols-rounded"
                  fontFamily={"Material Symbols Rounded"}
                  fill={infoColor}
                />
                <Text
                  ref={kiloRef}
                  x={width - parseInt(kilosTextWidth?.width) - width * 0.05}
                  y={height * 0.05 + parseInt(infoSize)}
                  text={kilos + " KG"}
                  fontSize={parseInt(infoSize)}
                  fontStyle="bold"
                  fontFamily={"DM sans"}
                  fill={infoColor}
                />
                <Text x={width} y={height} text={""} fontSize={1} />
                <Text x={0} y={height} text={""} fontSize={1} />
              </Group>
              {floors.map((floor, index) => (
                <Group key={floor} ref={el => (buttonRef.current[index] = el)}>
                  <Rect
                    x={getFloorPosition(floor).x}
                    y={getFloorPosition(floor).y}
                    width={(250 * width) / 1080}
                    height={(250 * width) / 1080}
                    strokeEnabled={enableStroke}
                    fillEnabled={enableFill}
                    fill={floorBtnColor}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    cornerRadius={parseInt(cornerRadius)}
                  />

                  <Text
                    ref={buttonTextRef}
                    fontSize={parseInt(floorTxTSize)}
                    verticalAlign="middle"
                    x={getTextPosition(floor).x}
                    y={getTextPosition(floor).y}
                    fontStyle="bold"
                    text={floor}
                    fill={floorTxTColor}
                  />
                </Group>
              ))}
              {addOpen && (
                <>
                  <Group ref={openRef}>
                    <Rect
                      x={getOpenPosition(false).x}
                      y={getOpenPosition(false).y}
                      width={(250 * width) / 1080}
                      height={(250 * width) / 1080}
                      strokeEnabled={enableStroke}
                      fillEnabled={enableFill}
                      fill={floorBtnColor}
                      stroke={strokeColor}
                      strokeWidth={strokeWidth}
                      cornerRadius={parseInt(cornerRadius)}
                    />
                    <Line
                      stroke={floorTxTColor}
                      strokeWidth={5}
                      lineJoin={"round"}
                      x={getOpenLinePosition(false).x}
                      y={getOpenLinePosition(false).y}
                      points={openPoints}
                    />
                    <Line
                      stroke={floorTxTColor}
                      strokeWidth={5}
                      lineJoin={"round"}
                      x={getLinePosition().x}
                      y={getLinePosition().y}
                      points={linePoints}
                    />
                    <Line
                      stroke={floorTxTColor}
                      strokeWidth={5}
                      lineJoin={"round"}
                      x={getOpenLinePosition(false).x + (250 * width) / 1080}
                      scaleX={-1}
                      y={getOpenLinePosition(false).y}
                      points={openPoints}
                    />
                  </Group>
                  <Group ref={closeRef}>
                    <Rect
                      x={getOpenPosition(true).x}
                      y={getOpenPosition(true).y}
                      width={(250 * width) / 1080}
                      height={(250 * width) / 1080}
                      strokeEnabled={enableStroke}
                      fillEnabled={enableFill}
                      fill={floorBtnColor}
                      stroke={strokeColor}
                      strokeWidth={strokeWidth}
                      cornerRadius={parseInt(cornerRadius)}
                    />
                    <Line
                      stroke={floorTxTColor}
                      strokeWidth={5}
                      lineJoin={"round"}
                      x={getOpenLinePosition(true).x}
                      y={getOpenLinePosition(true).y}
                      points={closePoints}
                    />
                    <Line
                      stroke={floorTxTColor}
                      strokeWidth={5}
                      lineJoin={"round"}
                      x={getOpenLinePosition(true).x + (250 * width) / 1080}
                      y={getOpenLinePosition(true).y}
                      points={closePoints}
                      scaleX={-1}
                    />
                  </Group>
                </>
              )}
              <Text
                ref={numberRef.current[0]}
                fontSize={parseInt(numberSize)}
                verticalAlign="top"
                x={width / 2 - numberTextWidth / 2}
                y={height * 0.2}
                fontStyle="bold"
                text={numberValue}
                fill={numberColor}
              />
              <Line
                closed
                ref={upArrowRef}
                x={width / 2 + numberTextWidth * 0.5}
                y={height * 0.2}
                points={arrowPoints}
                fill={numberColor}
              />
            </Layer>
          </Stage>
        </div>
      </div>
    </div>
  )
}

export default AquariusEdit
export const query = graphql`
  query AquariusQuery($locale: String!) {
    aquarius: datoCmsAquariusEditor(locale: { eq: $locale }) {
      title
      slug
    }
  }
`
