import React from "react"
import { AiOutlineClose } from "react-icons/ai"

const InnerSidebar = ({ isOpen, content, handleSidebar }) => {
  return (
    <div
      className={`
    ${
      isOpen
        ? "relative z-30 h-64 w-full gap-4 overflow-auto bg-gray-100 p-4  lg:h-full lg:w-80 lg:p-4"
        : "relative  h-0 w-full overflow-auto bg-gray-100 p-0  lg:h-0 lg:w-0 lg:p-0"
    } 
   `}
    >
      <div className="relative top-0 right-0 z-50 flex w-full justify-end ">
        <AiOutlineClose
          className="cursor-pointer  hover:opacity-90"
          onClick={handleSidebar}
        />
      </div>
      {content}
    </div>
  )
}

export default InnerSidebar
