import React from "react"

const NewSlidePick = ({ value, setValue, label, max, min }) => {
  return (
    <div className="w-full ">
      <div className="flex w-full items-center justify-between">
        <label
          for={label}
          className="form-label text-m  h-full  w-full  font-bold text-gray-700"
        >
          {label}
        </label>
        <div className="w-14">
          <span className="focus:shadow-outline w-full appearance-none rounded border bg-white  px-3 leading-relaxed text-gray-700 shadow focus:outline-none">
            {value}
          </span>
        </div>
      </div>

      <input
        min={min}
        max={max}
        step="1"
        value={value}
        onChange={e => {
          setValue(e.target.value)
        }}
        id={label}
        type="range"
        className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-300"
      />
    </div>
  )
}

export default NewSlidePick
