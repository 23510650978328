import React, { useEffect, useState } from "react"
import CustomColor from "./CustomColor"

export default function ColorPick({ label, color, onChange, setSelected }) {
  const [swatchOpen, setOpenSwatch] = useState(false)
  useEffect(() => {
    setSelected(swatchOpen)
  }, [swatchOpen])

  return (
    <>
      {swatchOpen ? (
        <CustomColor
          label={label}
          hex={color}
          color={color}
          onChange={color => onChange(color.hex)}
          setOpenSwatch={setOpenSwatch}
        />
      ) : (
        <div
          onClick={() => {
            setOpenSwatch(!swatchOpen)
          }}
          className=" flex  w-full cursor-pointer items-center justify-between gap-4 font-bold"
        >
          <span>{label}</span>

          <div
            className="h-5 w-11 rounded border-2 border-gray-200 hover:opacity-90"
            style={{ background: color }}
          />
        </div>
      )}
    </>
  )
}
