import React, { useState } from "react"
import BackgroundPicker from "./BackgroundPicker"
import ColorPick from "./ColorPick"
import Toggle from "./Toggle"
const BackgroundTab = props => {
  const [pickColor, setColorPick] = useState(false)

  return (
    <div className="relative flex h-full w-full flex-col gap-8 py-4">
      {!pickColor && (
        <>
          <BackgroundPicker
            backgroundFile={props.backgroundFile}
            backgroundFiles={props.backgroundFiles}
            setBackgroundFiles={props.setBackgroundFiles}
            backgroundURL={props.backgroundURL}
            setBackgroundURL={props.setBackgroundURL}
            previewBackgroundURL={props.previewBackgroundURL}
            setPreviewBackgroundURL={props.setPreviewBackgroundURL}
            handleBgFile={props.handleBgFile}
            warning={props.warning}
            alertImgSize={props.alertImgSize}
            setWarning={props.setWarning}
            setAlertImgSize={props.setAlertImgSize}
            floors={props.floors}
          />
          <Toggle
            setValue={props.setTransparency}
            value={props.transparency}
            name="Darken Background"
          />
        </>
      )}

      <ColorPick
        setSelected={setColorPick}
        color={props.color}
        onChange={props.setColor}
        label={props.label}
      />
    </div>
  )
}

export default BackgroundTab
