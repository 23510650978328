import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"

const BackgroundPicker = props => {
  const removeBg = (url, index) => {
    let filteredArray = props.backgroundURL.filter(item => item !== url)
    let filteredFiles = props.backgroundFiles.filter(
      item => item.floor !== index
    )
    props.setBackgroundURL(filteredArray)
    props.setBackgroundFiles(filteredFiles)
  }
  const onSelectChange = (e, index) => {
    let newArray = [...props.backgroundFiles]
    let findExistingFloor = newArray.filter(
      file => file.floor === e.target.value
    )
    const existingFloorIndex = newArray.indexOf(findExistingFloor[0])
    if (existingFloorIndex !== -1) {
      newArray[existingFloorIndex].floor = newArray[index].floor
      newArray[index].floor = e.target.value
    }
    props.setBackgroundFiles(newArray)
  }
  const [tooMany, setTooMany] = useState(false)
  useEffect(() => {
    if (
      props.backgroundURL.length > 0 &&
      props.floors.length !== props.backgroundURL.length
    ) {
      setTooMany(true)
    } else {
      setTooMany(false)
    }
  }, [props.floors, props.backgroundURL])

  return (
    <div className="flex flex-col ">
      <span className=" font-semibold">Upload Background Image</span>
      <div className="mt-2 flex w-full items-center justify-center">
        <label
          for="dropzone-file"
          className="flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 "
        >
          <div className="flex flex-col items-center justify-center pt-6 pb-6">
            <svg
              aria-hidden="true"
              className="mb-3 h-10 w-10 text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-gray-600 ">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-600 ">JPG, PNG, JPEG (MAX. 2MB)</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            value={props.backgroundFile}
            onChange={e => props.handleBgFile(e)}
            multiple
            accept="image/jpg,image/jpeg,image/png ,.png ,.jpg,.jpeg "
          />
        </label>
      </div>
      {props.alertImgSize && (
        <div className="alert-error">
          <span className="text-sm">
            One or more images have not been uploaded because its file size is
            too big <span className="font-semibold">{">"}3MB</span>
          </span>
        </div>
      )}
      {tooMany && (
        <div className="alert-info">
          {`You've uploaded ${props.backgroundURL.length} images for ${props.floors.length} floors`}
        </div>
      )}
      {props.backgroundURL.length > 0 && (
        <div className="mt-4 flex flex-col gap-4">
          {props.warning && (
            <div className="alert-warning">
              <span className="text-sm">
                One of the images is not meant for vertical screens, please use
                an image with aspect ratio of
                <span className="font-semibold"> 9/16 E.G. (1080x1920)</span>
              </span>
            </div>
          )}
          {props.backgroundURL.map((url, index) => (
            <div
              className=" flex h-full  cursor-pointer  justify-between transition-all"
              onClick={() => {
                props.setPreviewBackgroundURL(url)
              }}
            >
              <img
                className="min-w-1/4 opacity-1 m-0 w-1/4 transition-all hover:opacity-80"
                key={url}
                src={url}
              />
              <div className="flex flex-col items-end justify-between gap-2">
                <div
                  onClick={() => removeBg(url, index)}
                  className=" right-0 top-0 rounded-full  p-1 transition-all hover:bg-slate-50"
                >
                  <AiOutlineClose />
                </div>
                <label
                  for="floors"
                  className="mb-2 block  text-sm font-medium text-gray-900 "
                >
                  <select
                    defaultValue={props.backgroundFiles[index].floor}
                    value={props.backgroundFiles[index].floor}
                    onChange={e => onSelectChange(e, index)}
                    id="floors"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                  >
                    {props.floors.map(floor => (
                      <option key={floor} value={floor}>
                        {"Floor: " + floor}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default BackgroundPicker
