import React from "react"

const FilePicker = props => {
  return (
    <div className="flex flex-col ">
      <span className="mb-2  font-semibold">{props.label}</span>
      <div className="flex w-full items-center justify-center">
        <label
          for="dropzone-file"
          className="flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 "
        >
          <div className="flex flex-col items-center justify-center pt-6 pb-6">
            <svg
              aria-hidden="true"
              className="mb-3 h-10 w-10 text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-gray-600 ">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-600 ">JPG, PNG, JPEG (MAX. 2MB)</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            value={props.file}
            onChange={e => props.handleFile(e)}
            accept="image/jpg,image/jpeg,image/png ,.png ,.jpg,.jpeg "
          />
        </label>
      </div>
    </div>
  )
}

export default FilePicker
