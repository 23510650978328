import React from "react"

const Toggle = ({ name, setValue, value }) => {
  return (
    <label className="relative flex cursor-pointer  items-center justify-between">
      <span className="  font-semibold text-gray-900">{name}</span>

      <input
        type="checkbox"
        name={name}
        id={name}
        onClick={e => {
          setValue(e.target.checked)
        }}
        defaultChecked={value}
        value={value}
        className="peer sr-only"
      />
      <div className="after:border-gray-340 peer h-6 w-11 rounded-full bg-gray-300 after:absolute after:top-[2px] after:right-[24px] after:h-5 after:w-5 after:rounded-full after:border after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300"></div>
    </label>
  )
}

export default Toggle
