import React, { useState } from "react"
import { BiRectangle } from "react-icons/bi"
import { BsCircle } from "react-icons/bs"
import AmountPicker from "./AmountPicker"
import ColorPick from "./ColorPick"
import NewSlidePick from "./newSlidePick"
import Toggle from "./Toggle"
const ButtonTab = props => {
  const [pickColor, setColorPick] = useState(false)

  return (
    <div className={`relative flex h-full w-full flex-col   gap-8 lg:gap-8`}>
      {!pickColor && (
        <AmountPicker setValue={props.setFloors} value={props.floors} />
      )}

      {!pickColor && (
        <>
          <Toggle
            setValue={props.setAddOpen}
            value={props.addOpen}
            name="Add Open Close Buttons"
          />
          <Toggle
            setValue={props.setEnableFill}
            value={props.enableFill}
            name="Enable Background Color"
          />
        </>
      )}
      {props.enableFill && (
        <ColorPick
          setSelected={setColorPick}
          label="Background Button Color"
          color={props.floorBtnColor}
          onChange={props.setFloorBtnColor}
        />
      )}
      <ColorPick
        label="Button Text Color"
        setSelected={setColorPick}
        color={props.floorTxTColor}
        onChange={props.setFloorTxTColor}
      />
      <NewSlidePick
        label="Font Size"
        value={props.floorTxTSize}
        setValue={props.setFloorTxTSize}
        min={12}
        max={64}
      />
      <ColorPick
        setSelected={setColorPick}
        color={props.frameColor}
        onChange={props.setFrameColor}
        label="Button Color frame"
      />
      <Toggle
        setValue={props.setEnableStroke}
        value={props.enableStroke}
        name="Enable Stroke"
      />
      {props.enableStroke && (
        <>
          <ColorPick
            setSelected={setColorPick}
            label="Stroke Color"
            color={props.strokeColor}
            onChange={props.setStrokeColor}
          />
        </>
      )}
      {!pickColor && (
        <div className="flex items-center justify-between pb-4 lg:flex-col lg:items-start lg:gap-2">
          <span className="font-semibold">Button Shape</span>
          <div className="inline-flex rounded-md" role="group">
            <button
              onClick={() => props.setCornerRadius(8)}
              type="button"
              className={`
            ${
              props.cornerRadius === 8
                ? "z-10 text-blue-700 ring-2 ring-blue-700"
                : ""
            }
            inline-flex items-center rounded-l-lg border border-gray-200 bg-white py-2 px-4 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700`}
            >
              <BiRectangle className="mr-3 h-4 w-4" /> Square
            </button>
            <button
              onClick={() => props.setCornerRadius(50)}
              type="button"
              className={`
            ${
              props.cornerRadius === 50
                ? "z-10 text-blue-700 ring-2 ring-blue-700"
                : ""
            }
            inline-flex items-center rounded-r-lg border border-gray-200 bg-white py-2 px-4 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700`}
            >
              <BsCircle className="mr-3 h-4 w-4" /> Round
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ButtonTab
